<template>
  <div v-if="loaded">
    <v-row dense v-if="!is_payment">
      <v-col cols="12" xl="8" lg="8" md="12" sm="12" xs="12">
        <emb-perfect-scrollbar class="scroll-area" style="height: 92vh">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" class="pr-2 mt-5">
              <v-tabs
                fixed-tabs
                v-model="tab"
                background-color="primary"
                class="rounded"
                centered
                dark
              >
                <v-tab href="#tab-1" v-if="$route.params.id"> ALOJAMIENTO </v-tab>
                <v-tab href="#tab-2"> PRODUCTOS / SERVICIOS </v-tab>
                <!-- <v-tab href="#tab-3">
                  AGREGAR -
                </v-tab> -->
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item :key="1" :value="'tab-' + 1">
                  <v-row dense>
                    <v-col
                      cols="12"
                      xl="12"
                      lg="12"
                      md="12"
                      sm="12"
                      class="pr-1"
                    >
                      <v-card color="secondary" pointer dark class="pa-4 mt-2">
                        <!-- <strong class="py-4 text-overline mb-4"
                          >DATOS DEL ALOJAMIENTO</strong
                        >
                        <v-divider></v-divider> -->
                        <!-- <v-row dense>
                          <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                            <div
                              class="form-group"
                              :class="{ 'error--text': errors.date_of_exit }"
                            >
                              <label>Fecha de ingreso</label>
                              <el-date-picker
                                v-model="form.date_of_entry"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm"
                                format="dd/MM/yyyy HH:mm"
                                placeholder="Seleccionar"
                              >
                              </el-date-picker>
                              <small
                                class="error--text"
                                v-if="errors.date_of_exit"
                                v-text="errors.date_of_exit[0]"
                              ></small>
                            </div>
                          </v-col>
                        </v-row> -->
                        <v-row dense>
                          <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                            <div class="form-group">
                              <label>Tarifa</label>
                              <el-select
                                v-model="form.period_id"
                                dusk="period_id"
                                @change="changePeriod()"
                              >
                                <el-option
                                  v-for="option in periods"
                                  :key="option.id"
                                  :value="option.id"
                                  :label="option.name"
                                ></el-option>
                              </el-select>
                            </div>
                          </v-col>
                          <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                            <div class="form-group">
                              <label>Motivo de viaje</label>
                              <el-select v-model="form.reason_travel_id">
                                <el-option
                                  v-for="option in reason_travel"
                                  :key="option.id"
                                  :value="option.id"
                                  :label="option.name"
                                ></el-option>
                              </el-select>
                            </div>
                          </v-col>

                          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                            <div class="form-group">
                              <label>Observaciones</label>
                              <el-input v-model="form.observations"></el-input>
                              <small
                                class="error--text"
                                v-if="errors.observations"
                                v-text="errors.observations[0]"
                              ></small>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-row dense class="mt-2">
                        <v-col
                          cols="12"
                          xl="1"
                          lg="1"
                          md="2"
                          sm="3"
                          v-for="(rec, i) in rooms"
                          :key="i"
                        >
                          <v-card
                            hover
                            color="secondary"
                            @click="clickAddRoom(rec.id)"
                            class="mb-2"
                          >
                            <v-row dense>
                              <v-col cols="12" lg="12" class="text-center">
                                <h4 class="white--text">{{ rec.name }}</h4>
                                <h3>{{ rec.room_type_short }}</h3>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :key="2" :value="'tab-' + 2">
                  <v-row dense>
                    <v-col cols="12" xl="12" lg="12" class="pr-1 pt-3">
                      <el-input
                        placeholder="Buscar..."
                        v-model="input_item"
                        @input="searchItems"
                        autofocus
                      ></el-input>
                    </v-col>
                    <v-col
                      cols="12"
                      xl="2"
                      lg="3"
                      md="3"
                      sm="4"
                      v-for="(item, i) in items"
                      :key="i"
                      class="pr-2 d-flex align-items-stretch"
                    >
                      <v-card
                        hover
                        color="secondary"
                        @click="clickAddItem(item, i)"
                        class="text-center"
                      >
                        <v-img
                          :src="item.image"
                          class="rellow--text align-center"
                          gradient="to bottom, rgba(0,0,0,.7), rgba(0,0,0,.9)"
                          height="110px"
                        >
                          <small class="ma-1">
                            {{ item.full_name }}
                          </small>
                        </v-img>

                        <small class="mt-2">
                          {{
                            item.description
                              ? item.description
                              : "NO TIENE DESCRIPCIÓN"
                          }}
                        </small>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <small>
                            {{ item.currency_type_symbol }}
                            {{ item.sale_unit_price }}
                          </small>
                          <!-- <el-input class="float-right ma-" size="mini" v-model="item.sale_unit_price"></el-input> -->
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </emb-perfect-scrollbar>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="12"
        sm="12"
        xs="12"
        style="height: calc(100vh - 65px)"
        class="secondary"
      >
        <div class="h-80" style="overflow-y: auto; overflow-x: hidden">
          <v-row dense class="m-0 p-0">
            <v-col cols="12" xl="10" lg="10" md="10" sm="8" class="p-0 mt-3">
              <el-select
                v-model="form.customer_id"
                filterable
                remote
                dusk="customer_id"
                placeholder="Cliente"
                :remote-method="searchRemoteCustomers"
                @change="changeCustomer"
                :loading="loading_search"
                loading-text="Cargando..."
              >
                <el-option
                  v-for="option in customers"
                  :key="option.id"
                  :value="option.id"
                  :label="option.description"
                ></el-option>
              </el-select>
              <small
                class="error--text"
                v-if="errors.customer_id"
                v-text="errors.customer_id[0]"
              ></small>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="4" class="p-0 mt-3">
              <el-button
                type="primary"
                class="btn-block"
                @click.prevent="showDialogNewPerson = true"
                >Nuevo</el-button
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" xl="12" class="p-0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Cant.</th>
                      <th class="text-left">Producto</th>
                      <th class="text-left">Precio</th>
                      <th class="text-left">Acc.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(record, index) in form.items"
                      :key="index"
                      class="hover"
                    >
                      <template v-if="currentIndex != index">
                        <td width="20%" class="pr-1 pl-0">
                          <el-input
                            v-model="record.item.aux_quantity"
                            size="mini"
                            @input="clickAddItem(record, index, true)"
                            class="input-text-right"
                            :readonly="form.period_id == '01' ? true : false"
                          ></el-input>
                        </td>
                        <td width="50%" class="pa-0 align-middle">
                          <template v-if="record.item.full_name.length > 30">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="record.item.full_name"
                              placement="top"
                            >
                              <div>{{ record.item.name }}</div>
                            </el-tooltip>
                          </template>
                          <template v-else>
                            <div class="mb-0">
                              {{ record.item.full_name }}
                            </div>
                          </template>
                          <!-- <strong class="mb-0">{{ currency_type.symbol }} {{ item.total }}</strong> -->
                        </td>
                        <td width="20%" class="pl-0 pr-1">
                          <el-input
                            v-model="record.unit_price"
                            size="mini"
                            @input="calculateRowTotal(record.item, index)"
                            class="input-text-right"
                            :readonly="permission ? false : true"
                          ></el-input>
                        </td>
                        <td class="text-right align-middle pa-0">
                          <v-btn
                            fab
                            dark
                            x-small
                            color="accent"
                            @click="getCurrentIndex(index)"
                          >
                            <v-icon dark>mdi-settings</v-icon>
                          </v-btn>
                        </td>
                      </template>
                      <template v-else>
                        <td></td>
                        <td
                          colspan="1"
                          class="text-center align-middle"
                          width="30%"
                        >
                          <el-input-number
                              v-model="record.number_of_people"
                              :min="1"
                              :max="10"
                            ></el-input-number>
                        </td>
                        <td></td>
                        <td class="text-right align-middle pa-0">
                          <v-btn
                            class="mr-1"
                            fab
                            dark
                            x-small
                            color="error"
                            @click="clickDeleteItem(index)"
                          >
                            <v-icon dark>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="success"
                            @click="clearCurrentIndex"
                          >
                            <v-icon dark>mdi-check-all</v-icon>
                          </v-btn>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
        <div
          class="h-20 white--text"
          style="overflow-y: auto; overflow-x: hidden"
        >
          <div class="m-0 p-0">
            <v-row dense>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <h4>TOTAL:</h4>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <h4 class="text-right">
                  {{ currency_type.symbol }} {{ form.total }}
                </h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xl="6" lg="6" md="6">
                <v-btn
                  block
                  outlined
                  color="error"
                  class="white--text"
                  @click="backTo()"
                >
                  <v-icon left dark>mdi-chevron-left</v-icon>
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6">
                <v-btn
                  color="accent"
                  block
                  :disabled="
                    form.total <= 0 || form.customer_id == null || onlySave
                  "
                  @click.prevent="submit"
                  :loading="loading_submit"
                >
                  Continuar con el pago
                  <v-icon right dark>mdi-send</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" xl="12" lg="12" md="12" v-if="onlySave">
                <v-btn
                  color="accent"
                  block
                  :disabled="form.total <= 0 || form.customer_id == null"
                  @click.prevent="submit"
                  :loading="loading_submit"
                >
                  Guardar y regresar
                  <v-icon right dark>mdi-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <template v-else>
      <payment-form :recordID="this.recordID"> </payment-form>
    </template>
    <person-form
      :showDialog.sync="showDialogNewPerson"
      :document_type_id="form.document_type_id"
      type="customers"
      :external="true"
    >
    </person-form>
  </div>
</template>

<script>
import { functions, exchangeRate } from "Mixins/functions";
import { calculateRowItem } from "Helpers/functions";
import { deletable } from "Mixins/deletable";
import PersonForm from "../People/Form";
import PaymentForm from "./Checkout";
import filter from "lodash/filter";
import round from "lodash/round";
import find from "lodash/find";
import dayjs from "dayjs";
// import PaymentForm from './partials/payment';

export default {
  components: { PersonForm, PaymentForm },
  mixins: [functions, exchangeRate, deletable],
  data() {
    return {
      resource: "rentals",
      loading_submit: false,
      is_payment: false,
      loaded: false,
      showDialogNewPerson: false,
      loading_search: false,
      input_item: null,
      payment: false,
      recordID: null,
      affectation_igv_types: [],
      form_item: {},
      row: {},
      errors: {},
      form: {},
      customers: [],
      items: [],
      onlyItem: {},
      warehouses: [],
      all_items: [],
      categories: [],
      establishment: {},
      currency_type: {},
      currency_types: [],
      orderNewId: null,
      change_room: false,
      deleted_item: false,
      categoryId: null,
      currentIndex: null,
      room: {},
      rooms: [],
      allRooms: [],
      periods: [],
      allRates: [],
      rates: [],
      rate: {},
      reason_travel: [],
      rental: null,
      permission: false,
      activeFavorite: false,
      showAllItem: false,
      execution_time: null,
      tab: null,
      onlySave: false,
      change_table: false,
      pickerOptions: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return dayjs().format("YYYY-MM-DD") > time;
        },
      },
    };
  },
  async created() {
    await this.getDynamicTables();
    await this.getStaticTables();

    this.$eventHub.$on("reloadDataPeople", (customer_id) => {
      this.reloadDataCustomers(customer_id);
    });

    await this.$eventHub.$on("backSale", () => {
      this.is_payment = false;
    });

    this.currentIndex = null;
    // this.onlySave = false;
  },
  methods: {
    async initForm() {
      this.activeFavorite = false;
      this.showAllItem = false;
      this.errors = {};
      // if (this.rental) {
      //   this.form = this.rental;
      //   this.form.rental_price = this.form.items[0].unit_price / this.form.number_of_days;
      //   this.form.date_of_entry = dayjs(this.form.date_of_entry).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   );
      //   this.form.date_of_exit = dayjs(this.form.date_of_exit).format(
      //     "YYYY-MM-DD"
      //   );

      //   this.onlySave = true;

      //   this.reloadDataCustomers(this.form.customer_id);
      // } else {
      this.form = {
        id: null,
        prefix: "TKT",
        establishment_id: null,
        warehouse_id: null,
        establishment: {},
        date_of_entry: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        date_of_due: dayjs().format("YYYY-MM-DD"),
        paid: true,
        period_id: "02",
        customer_id: null,
        reason_travel_id: 8,
        currency_type_id: "PEN",
        payment_condition_type_id: "1",
        purchase_order: null,
        exchange_rate_sale: 0,
        total_prepayment: 0,
        total_charge: 0,
        total_discount: 0,
        total_exportation: 0,
        total_free: 0,
        total_taxed: 0,
        total_unaffected: 0,
        total_exonerated: 0,
        total_igv: 0,
        total_base_isc: 0,
        total_isc: 0,
        total_base_other_taxes: 0,
        total_other_taxes: 0,
        total_taxes: 0,
        total_value: 0,
        total: 0,
        operation_type_id: "0101",
        items: [],
        rental_price: 0,
        discount: {
          discount_type_id: "02",
          description: "Descuento Global afecta a la base imponible",
          factor: 0,
          amount: 0,
          base: 0,
        },
      };
      this.initFormItem();
      // }
    },
    async getDynamicTables() {
      // let getCagories = localStorage.getItem("categories") ? 0 : 1;
      // let getItems = localStorage.getItem("items") ? 0 : 1;
      // let params = `getCagories=${getCagories}&getItems=${getItems}`;
      // let roomID = this.$route.params.id;
      await this.$http
        .get(`/${this.resource}/dynamic-tables`)
        .then((response) => {
          this.allRooms = response.data.rooms;
          this.rooms = this.allRooms;
          this.periods = response.data.periods;
          this.allRates = response.data.rates;
          // this.rental = response.data.rental;

          this.establishment = response.data.establishment;
          this.warehouses = response.data.warehouses;
          this.onlyItem = response.data.item;

          // if (response.data.items.length > 0) {
          this.all_items = response.data.items;
          // localStorage.setItem("items", JSON.stringify(this.items));
          // } else {
          //   this.all_items = JSON.parse(localStorage.getItem("items"));
          // }

          this.items = this.all_items;

          // if (response.data.categories.length > 0) {
          this.categories = response.data.categories;
          // localStorage.setItem("categories", JSON.stringify(this.categories));
          // } else {
          //   this.categories = JSON.parse(localStorage.getItem("categories"));
          // }
          this.permission = response.data.permission;

          this.initForm();

          this.form.establishment_id = this.establishment.id;
          this.form.establishment = this.establishment;

          this.form.warehouse_id =
            this.warehouses.length > 0 ? this.warehouses[0].id : null;

          if (this.$route.params.id) {
            this.rooms = this.allRooms.filter((row) => row.id != this.$route.params.id);
            this.clickAddRoom(this.$route.params.id)
          }

          this.loaded = true;
        });
    },
    async getStaticTables() {
      await this.$http
        .get(`/${this.resource}/static-tables`)
        .then((response) => {
          this.affectation_igv_types = response.data.affectation_igv_types;
          this.currency_types = response.data.currency_types;
          this.reason_travel = response.data.reason_travel;

          this.form.currency_type_id =
            this.currency_types.length > 0 ? this.currency_types[0].id : null;

          this.changeCurrencyType();
        });
    },
    // async changeDateOfExit() {
    //   let date = dayjs(this.form.date_of_exit).format("YYYY-MM-DD");
    //   let date_cvrt = dayjs(date);

    //   this.form.number_of_days =
    //     date_cvrt.diff(this.form.date_of_entry, "day") + 1;

    //   this.changeRate();
    // },
    initFormItem() {
      this.form_item = {
        item_id: null,
        item: {},
        room_id: null,
        is_active: true,
        is_rent: true,
        date_of_exit: dayjs().add(1, "day").format("YYYY-MM-DD"),
        time_of_exit: "12:00:00",
        number_of_people: 1,
        number_of_days: 1,
        affectation_igv_type_id: null,
        affectation_igv_type: {},
        has_isc: false,
        system_isc_type_id: null,
        percentage_isc: 0,
        suggested_price: 0,
        quantity: 1,
        aux_quantity: 1,
        unit_price_value: 0,
        unit_price: 0,
        charges: [],
        discount: {
          discount_type_id: "02",
          discount_type: { base: true },
          description: "Descuento que afecta la base imponible del IGV",
          percentage: 0,
          factor: 0,
          amount: 0,
          base: 0,
          is_amount: true,
        },
        attributes: [],
        has_igv: null,
        paid: true,
        note: null,
        item_unit_type_id: null,
      };
    },
    async clickAddRoom(roomID) {
      let exist_room = find(this.form.items, { room_id: roomID });

      if (exist_room) {
        return this.$message.error("Error! La habitación ya ha sido agregado");
      }

      this.initFormItem();

      let rental_price = 0;
      let exchangeRateSale = this.form.exchange_rate_sale;

      this.room = this.allRooms.find((element) => element.id == roomID);

      if (this.room) {
        this.rates = filter(this.allRates, {
          room_id: this.room.id,
          period_id: this.form.period_id,
        });

        if (this.rates.length == 0 || this.rates == undefined) {
          return this.$message.error(
            `Error! Agrega tarifas para esa habitación ${this.room.name}`
          );
        } else {
          rental_price = this.rates[0].price;
        }
      }

      let it = Object.assign({}, this.onlyItem);

      this.form_item.room_id = roomID;
      this.form_item.item = it;

      this.form_item.item.name += ` ${this.room.name}`;
      this.form_item.item.full_name = this.form_item.item.name;

      this.form_item.id = this.form_item.id ? this.form_item.id : null;
      this.form_item.unit_price_value = rental_price;
      this.form_item.item_id = 1;
      this.form_item.is_rent = true;
      this.form_item.has_igv = this.form_item.item.has_igv;
      this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
      this.form_item.quantity = 1;
      this.form_item.aux_quantity = 1;

      let unit_price = this.form_item.has_igv
        ? this.form_item.unit_price_value
        : this.form_item.unit_price_value * 1.10;

      this.form_item.unit_price = unit_price;
      this.form_item.item.unit_price = unit_price;
      this.form_item.item.presentation = null;

      this.form_item.charges = [];
      this.form_item.attributes = [];
      this.form_item.affectation_igv_type = find(this.affectation_igv_types, {
        id: this.form_item.affectation_igv_type_id,
      });
        
      this.assignPeriod();

      this.row = calculateRowItem(
        this.form_item,
        this.form.currency_type_id,
        exchangeRateSale
      );


      this.form.items.push(this.row);
      this.onlyItem.aux_quantity = 1;

      this.calculateTotal();
    },
    assignPeriod() {
      if (this.form.period_id == "01") {

        this.form_item.date_of_exit = dayjs().format("YYYY-MM-DD");
        this.form_item.time_of_exit = dayjs().add(3, "hour").format("HH:mm:ss");
        this.form_item.number_of_days = 1;

      } else if (this.form.period_id == "03") {

        this.form_item.date_of_exit = dayjs().add(7, "day").format("YYYY-MM-DD");
        this.form_item.time_of_exit = "12:00:00";
        this.form_item.number_of_days = 7;

      } else if (this.form.period_id == "04") {

        this.form_item.date_of_exit = dayjs().add(1, "month").format("YYYY-MM-DD");
        this.form_item.time_of_exit = "12:00:00";

        let date = dayjs(this.form_item.date_of_exit).format("YYYY-MM-DD");
        let date_cvrt = dayjs(date);

        this.form_item.number_of_days = date_cvrt.diff(this.form.date_of_entry, "day") + 1

      } else {
        
        this.form_item.date_of_exit = dayjs().add(this.form_item.number_of_days, "day").format("YYYY-MM-DD");
        this.form_item.time_of_exit = "12:00:00";
      }
    },
    changePeriod() {
      this.form.items.forEach((row, index) => {
          if (row.is_rent) {
            let room = this.allRooms.find((element) => element.id == row.room_id);
  
            if (room) {
              
              this.rates = filter(this.allRates, {
                room_id: room.id,
                period_id: this.form.period_id,
              });
  
              if (this.rates.length == 0 || this.rates == undefined) {
                this.$message.error(
                  `Error! Agrega tarifas para esa habitación ${room.name}`
                );
                return;
              } else {
                if (this.form.period_id == "01") {
  
                  row.date_of_exit = dayjs().format("YYYY-MM-DD");
                  row.time_of_exit = dayjs().add(3, "hour").format("HH:mm:ss");
                  row.number_of_days = 1;
  
                  
                } else if (this.form.period_id == "03") {
  
                  row.date_of_exit = dayjs().add(7, "day").format("YYYY-MM-DD");
                  row.time_of_exit = "12:00:00";
                  row.number_of_days = 1;
  
                } else if (this.form.period_id == "04") {
                  row.date_of_exit = dayjs().add(1, "month").format("YYYY-MM-DD");
                  row.time_of_exit = "12:00:00";
  
                  let date = dayjs(row.date_of_exit).format("YYYY-MM-DD");
                  let date_cvrt = dayjs(date);
  
                  row.number_of_days = date_cvrt.diff(this.form.date_of_entry, "day") + 1
  
                } else {
                  row.date_of_exit = dayjs().add(row.number_of_days, "day").format("YYYY-MM-DD");
                  row.time_of_exit = "12:00:00";
                  row.number_of_days = 1;
                }
  
                row.quantity = 1;
                row.item.aux_quantity = 1;

                row.item.unit_price = this.rates[0].price; 
                row.unit_price = this.rates[0].price; 
                // console.log(row.unit_price);
  
                this.row = calculateRowItem(
                  row,
                  this.form.currency_type_id,
                  this.form.exchange_rate_sale
                );

                console.log(this.row.unit_price);
                this.form.items[index] = this.row;
              }
            }
          }
      });

      this.calculateTotal();
    },
    searchRemoteCustomers(input) {
      if (input.length > 3) {
        this.loading_search = true;
        let parameters = `input=${input}`;
        this.$http
          .get(`/${this.resource}/search/customers?${parameters}`)
          .then((response) => {
            this.customers = response.data;
            this.loading_search = false;
          });
      } else {
        this.customers = [];
      }
    },
    changeCustomer() {
      let customer = find(this.customers, { id: this.form.customer_id });

      if (customer.undesirable) {
        this.$message.error("!Alerta! Cliente registrado como indeseable");
      }

      this.customer = customer;
      this.form.document_type_id =
        customer.identity_document_type_id == "6" ? "01" : "03";
    },
    searchItems() {
      if (this.input_item.length > 2) {
        // let inputFmt = this.input_item.toUpperCase();

        //   const items = JSON.parse(localStorage.getItem("items"));

        //   this.items = items.filter((favorite) => {
        //     return favorite.name.toUpperCase().indexOf(inputFmt) > -1;
        //   });
        let parameters = `input=${this.input_item}`;
        this.$http
          .get(`/${this.resource}/search/items?${parameters}`)
          .then((response) => {
            this.items = response.data;
          });
      } else {
        this.items = this.all_items;
      }
    },
    async clickAddItem(item, index, input = false) {
      let exchangeRateSale = this.form.exchange_rate_sale;

      let exist_item;
      if (item.item_id == 1) {
        
        exist_item = this.form.items[index];

      } else {

        exist_item = find(this.form.items, { item_id: item.item_id });
        index = this.form.items.indexOf(exist_item);
        
      }

      if (exist_item) {
        if (input) {
          exist_item.quantity = exist_item.item.aux_quantity;

          if (exist_item.is_rent) {
            
            if (this.form.period_id == "03") {

              exist_item.date_of_exit = dayjs().add(exist_item.quantity * 7, "day").format("YYYY-MM-DD");
              exist_item.time_of_exit = "12:00:00";

            } else if (this.form.period_id == "04") {
              exist_item.date_of_exit = dayjs().add(exist_item.quantity, "month").format("YYYY-MM-DD");
              exist_item.time_of_exit = "12:00:00";

            } else {
              exist_item.date_of_exit = dayjs().add(exist_item.quantity, "day").format("YYYY-MM-DD");
              exist_item.time_of_exit = "12:00:00";

            }

            let date = dayjs(exist_item.date_of_exit).format("YYYY-MM-DD");
            let date_cvrt = dayjs(date);

            exist_item.number_of_days = date_cvrt.diff(this.form.date_of_entry, "day") + 1;
          }
          
        } else {
          exist_item.quantity++;
          exist_item.item.aux_quantity++;
        }

        this.row = calculateRowItem(
          exist_item,
          this.form.currency_type_id,
          exchangeRateSale
        );
        this.form.items[index] = this.row;
      } else {
        this.form_item.item = item;
        this.form_item.is_rent = false;
        this.form_item.id = this.form_item.id ? this.form_item.id : null;
        this.form_item.unit_price_value = this.form_item.item.sale_unit_price;
        this.form_item.has_igv = this.form_item.item.has_igv;
        this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
        this.form_item.quantity = 1;
        this.form_item.aux_quantity = 1;

        let unit_price = this.form_item.has_igv
          ? this.form_item.unit_price_value
          : this.form_item.unit_price_value * 1.10;

        this.form_item.unit_price = unit_price;
        this.form_item.item.unit_price = unit_price;
        this.form_item.item.presentation = null;

        this.form_item.charges = [];
        this.form_item.attributes = [];
        this.form_item.affectation_igv_type = find(this.affectation_igv_types, {
          id: this.form_item.affectation_igv_type_id,
        });

        this.row = calculateRowItem(
          this.form_item,
          this.form.currency_type_id,
          exchangeRateSale
        );

        this.form.items.push(this.row);
        item.aux_quantity = 1;
      }

      if (!input) {
        this.$message.success("Producto agregado correctamente!");
      }

      this.calculateTotal();
    },
    changeDateOfIssue() {
      this.searchExchangeRateByDate(this.form.date_of_issue).then(
        (response) => {
          this.form.exchange_rate_sale = response;
        }
      );
    },
    clickDeleteItem(index) {
      if (this.form.items[index].id != null) {
        this.remove(
          `/${this.resource}/order_item/${this.form.items[index].id}`
        ).then(() => {
          this.form.items.splice(index, 1);
          this.deleted_item = true;
          this.calculateTotal();
          this.submit();
          this.currentIndex = null;
        });
      } else {
        this.form.items.splice(index, 1);
        this.calculateTotal();
        this.currentIndex = null;
      }
    },
    changeCurrencyType() {
      this.currency_type = find(this.currency_types, {
        id: this.form.currency_type_id,
      });
    },
    calculateRowTotal(item, index) {

      let row = this.form.items[index];
      row.item.unit_price = row.unit_price;

      let currency_type_id_new = this.form.currency_type_id;
      let exchange_rate_sale = this.form.exchange_rate_sale;
      let currency_type_id_old = row.item.currency_type_id;
      let unit_price = parseFloat(row.item.unit_price);

      if (
        currency_type_id_old === "PEN" &&
        currency_type_id_old !== currency_type_id_new
      ) {
        unit_price = unit_price / exchange_rate_sale;
      }

      if (
        currency_type_id_new === "PEN" &&
        currency_type_id_old !== currency_type_id_new
      ) {
        unit_price = unit_price * exchange_rate_sale;
      }

      let percentage_igv = 10;
      let unit_value = row.unit_price;

      if (row.affectation_igv_type_id === "10") {
        unit_value = row.unit_price / (1 + percentage_igv / 100);
      }

      console.log(unit_price);

      // row.unit_value = round(unit_value, 4)

      row.unit_value = unit_value;

      let total_value_partial = unit_value * row.quantity;

      /* Discounts */
      let discount_base = 0;
      let discount_no_base = 0;

      /* Charges */
      let charge_base = 0;
      let charge_no_base = 0;

      let total_isc = 0;
      let total_other_taxes = 0;

      let total_discount = discount_base + discount_no_base;
      let total_charge = charge_base + charge_no_base;
      let total_value = total_value_partial - total_discount + total_charge;
      let total_base_igv = total_value_partial - discount_base + total_isc;

      let total_igv = 0;

      if (row.affectation_igv_type_id === "10") {
        total_igv = (total_base_igv * percentage_igv) / 100;
      }
      if (row.affectation_igv_type_id === "20") {
        //Exonerated
        total_igv = 0;
      }
      if (row.affectation_igv_type_id === "30") {
        //Unaffected
        total_igv = 0;
      }

      let total_taxes = total_igv + total_isc + total_other_taxes;
      let total = total_value + total_taxes;

      row.total_charge = total_charge;
      row.total_discount = total_discount;
      // row.total_charge = total_charge
      row.total_value = total_value;
      row.total_base_igv = total_base_igv;
      row.total_igv = total_igv;
      row.total_taxes = total_taxes;
      row.total = round(total, 2);

      // descomentar para op. gratuitas
      // if (row.affectation_igv_type.free) {
      //     row.price_type_id = '02'
      //     row.unit_value = 0
      //     // row.total_value = 0
      //     row.total = 0
      // }

      this.calculateTotal();
    },
    calculateTotal() {
      let total_discount = 0;
      let total_charge = 0;
      let total_exportation = 0;
      let total_taxed = 0;
      let total_exonerated = 0;
      let total_unaffected = 0;
      let total_free = 0;
      let total_igv = 0;
      let total_value = 0;
      let total = 0;
      this.form.items.forEach((row) => {
        total_discount += parseFloat(row.total_discount);
        total_charge += parseFloat(row.total_charge);

        if (row.affectation_igv_type_id === "10") {
          total_taxed += parseFloat(row.total_value);
        }
        if (row.affectation_igv_type_id === "20") {
          total_exonerated += parseFloat(row.total_value);
        }
        if (row.affectation_igv_type_id === "30") {
          total_unaffected += parseFloat(row.total_value);
        }
        if (row.affectation_igv_type_id === "40") {
          total_exportation += parseFloat(row.total_value);
        }
        if (["10", "20", "30", "40"].indexOf(row.affectation_igv_type_id) < 0) {
          total_free += parseFloat(row.total_value);
        }
        if (
          ["10", "20", "30", "40"].indexOf(row.affectation_igv_type_id) > -1
        ) {
          total_igv += parseFloat(row.total_igv);
          total += parseFloat(row.total);
        }
        total_value += parseFloat(row.total_value);
      });

      this.form.total_exportation = round(total_exportation, 2);
      this.form.total_discount = round(total_discount, 2);
      this.form.total_charge = round(total_charge, 2);
      this.form.total_taxed = round(total_taxed, 2);
      this.form.total_exonerated = round(total_exonerated, 2);
      this.form.total_unaffected = round(total_unaffected, 2);
      this.form.total_free = round(total_free, 2);
      this.form.total_igv = round(total_igv, 2);
      this.form.total_value = round(total_value, 2);
      this.form.total_taxes = round(total_igv, 2);
      this.form.total = round(total, 2);
    },
    submit() {
      this.loading_submit = true;
      this.$http
        .post(`/${this.resource}`, this.form)
        .then((response) => {
          if (response.data.success) {
            this.form.id = response.data.id;
            this.recordID = this.form.id;
            this.form.items = response.data.items;

            // this.$message.success(response.data.message);
            if (this.onlySave) {
              this.backTo();
              return;
            }
            this.is_payment = true;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.$message.error(error.response.data.message);
          }
        })
        .then(() => {
          this.loading_submit = false;
        });
    },
    backTo() {
      this.$router.push({ name: "Rent" });
    },
    getCurrentIndex(index) {
      this.currentIndex = index;
    },
    clearCurrentIndex() {
      this.currentIndex = null;
    },
    
    changePaidState() {
      this.form.items[0].paid = this.form.paid;
    },
    reloadDataCustomers(customer_id) {
      this.$http
        .get(`/${this.resource}/search/customer/${customer_id}`)
        .then((response) => {
          this.customers = response.data;
          this.form.customer_id = customer_id;
        });
    },
    async makePayment() {
      this.form.establishment_id = this.establishment.id;

      this.form.warehouse_id =
        this.warehouses.length > 0 ? this.warehouses[0].id : null;
      // await this.sleep(500);
      this.is_payment = true;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>
